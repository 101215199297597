console.log("global");

var ajax_params = {
    ajaxurl: 'https://dev7.odysseydesignhosting.com/wp-admin/admin-ajax.php'
};

jQuery(document).ready(function ($) {
    "use strict";

        

        if ($('#product-search-input').length) {
            $('#product-search-input').on('keyup', function() {
                var searchQuery = $(this).val();
                if (typeof ajax_product_search_params !== 'undefined') {
                    $.ajax({
                        url: ajax_product_search_params.ajax_url,
                        type: 'POST',
                        data: {
                            action: 'product_search',
                            search_query: searchQuery
                        },
                        success: function(data) {
                            $('.woocommerce-container').html(data);
                        }
                    });
                } else {
                    console.error('ajax_product_search_params is not defined');
                }
            });
        }

});

document.addEventListener('DOMContentLoaded', function () {
    // Function to handle Distributors Load More Function
    const loadMoreButton = document.getElementById('load-more-distributors');
    let visibleIndex = 8;

    if (loadMoreButton) {
        loadMoreButton.addEventListener('click', function() {
            const hiddenDistributors = document.querySelectorAll('.each-distributor[data-index]');
            for (let i = visibleIndex; i < visibleIndex + 8; i++) {
                if (hiddenDistributors[i]) {
                    hiddenDistributors[i].style.display = 'block';
                }
            }
            visibleIndex += 8;

            // Hide the load more button if all distributors are visible
            if (visibleIndex >= hiddenDistributors.length) {
                loadMoreButton.style.display = 'none';
            }
        });
    }
});